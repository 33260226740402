















































































































































































import { Vue, Component, Watch, ProvideReactive } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { Route } from 'vue-router';
import io from 'socket.io-client';
import VueSocketIO from 'vue-socket.io';
import CrmAppBar from '@/components/crm/app-bar.vue';
import { INavData } from '@/interfaces/nav-data.interface';
import ClientModel from '@/models/crm/client.model';
import ClientService from '@/services/crm/client.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import RouterService from '@/services/router.service';
import CrmClientSearchField from '@/components/crm/client-search-field.vue';
import NavBar from '@/components/nav-bar.vue';
import ContactService from '@/services/crm/contact.service';
import AttendantService from '@/services/attendant.service';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import ContentDialog from '@/components/content-dialog.vue';
import CrmEmailView from '@/components/crm/email-view.vue';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import CrmAdvancedSearch from '@/components/crm/advanced-search.vue';
import CrmAttachmentsViewer from '@/components/crm/attachments-viewer.vue';
import SessionService from '@/services/session.service';
import CrmChat from '@/components/crm/chat.vue';
import ConversationService from '@/services/crm/conversation.service';
import ConversationUserPermissionsModel from '@/models/crm/conversation-user-permissions.model';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import ConfirmationDialog from '@/components/confirmation-dialog.vue';
import CrmProspectForm from '@/components/crm/prospect-form.vue';
import ProspectModel from '@/models/crm/prospect.model';
import SettingsModel from '@/models/crm/settings.model';
import SettingsService from '@/services/crm/settings.service';
import CrmAddToServiceQueueForm from '@/components/crm/add-to-service-queue-form.vue';
import { ICreateConversation } from '@/interfaces/crm/create-conversation.interface';
import { ConversationContactStatusEnum } from '@/enums/crm/conversation-contact-status.enum';
import CrmCallCenter from '@/components/crm/call-center.vue';
import { CallCenterEventEnum } from '@/enums/crm/call-center-event.enum';
import CrmCallCenterUserStatusCard from '@/components/crm/call-center-user-status-card.vue';
import CallCenterCallModel from '@/models/crm/call-center-call.model';
import { CallCenterCallTypeEnum } from '@/enums/crm/call-center-call-type.enum';
import { ConversationTypeEnum } from '@/enums/crm/conversation-type.enum';
import ProspectService from '@/services/crm/prospect.service';
import { eventBus } from '@/utils/eventBus';
import { CallCenterPartnerEnum } from '@/enums/crm/call-center/call-center-partner.enum';

@Component({
  components: {
    CrmAppBar,
    NavBar,
    ContentDialog,
    ConfirmationDialog,
    CrmProspectForm,
    CrmEmailView,
    CrmClientSearchField,
    CrmAdvancedSearch,
    CrmAttachmentsViewer,
    CrmChat,
    CrmAddToServiceQueueForm,
    CrmCallCenter,
    CrmCallCenterUserStatusCard,
  },
})
export default class CrmIndex extends Vue {
  @inject(InjectionIdEnum.SessionService)
  private sessionService!: SessionService;

  @inject(InjectionIdEnum.AttendantService)
  private attendantService!: AttendantService;

  @inject(InjectionIdEnum.CrmClientService)
  private clientService!: ClientService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  @inject(InjectionIdEnum.CrmConversationService)
  private conversationService!: ConversationService;

  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @inject(InjectionIdEnum.CrmProspectService)
  private prospectService!: ProspectService;

  @ProvideReactive('activeClient')
  activeClient: ClientModel | null = null;

  @ProvideReactive('userContactInfo')
  userContactInfo: UserContactInfo | null = null;

  @ProvideReactive('conversationUserPermission')
  conversationUserPermission: ConversationUserPermissionsModel | null = null;

  @ProvideReactive('clientType')
  clientType = this.sessionService.clientType;

  @ProvideReactive('settings')
  settings: SettingsModel | null = null;

  overlayCallCenter = false;

  drawerCallCenter = false;

  showDrawerCallCenter = false;

  internalClientType = this.sessionService.clientType;

  fixedSecondaryMenuData: INavData[] = [
    {
      name: `${this.$t('crm.navigation.salesForceAdditionalInfo')}`,
      description: `${this.$t('crm.navigation.description.salesForceAdditionalInfo')}`,
      icon: 'mdi-briefcase',
      route: 'CrmSalesForceInformations',
    },
    {
      name: `${this.$t('crm.navigation.financialInformation')}`,
      icon: 'mdi-currency-usd',
      route: 'CrmFinancialInformations',
    },
    {
      name: `${this.$t('crm.navigation.scheduling')}`,
      icon: 'mdi-calendar-month-outline',
      route: 'CrmCalendar',
    },
    {
      name: `${this.$t('crm.navigation.attendances')}`,
      icon: 'mdi-headset',
      route: 'CrmAttendance',
    },
    {
      name: `${this.$t('crm.navigation.processes')}`,
      icon: 'mdi-sitemap',
      route: 'CrmProcesses',
    },
    {
      name: `${this.$t('crm.navigation.orders')}`,
      icon: 'mdi-cart',
      route: 'CrmOrders',
    },
    {
      name: `${this.$t('crm.navigation.sales')}`,
      icon: 'mdi-finance',
      route: 'CrmSales',
    },
    {
      name: `${this.$t('crm.navigation.sales')}`,
      icon: 'mdi-finance',
      route: 'CrmSalesErp',
    },
    {
      name: `${this.$t('crm.navigation.visitsMade')}`,
      icon: 'mdi-store',
      route: 'CrmVisitsMade',
    },
    {
      name: `${this.$t('crm.navigation.clientsManagement')}`,
      icon: 'mdi-list-status',
      route: 'CrmClientsManagement',
    },
    {
      name: `${this.$t('crm.navigation.emails')}`,
      icon: 'mdi-email-outline',
      route: 'CrmEmails',
    },
    {
      name: `${this.$t('crm.navigation.chats')}`,
      icon: 'mdi-chat',
      route: 'CrmChats',
    },
  ];

  showStatusCallCenterHeader = this.sessionService.showStatusCallCenterHeader || false;

  mainMenuData: INavData[] = [
    {
      name: `${this.$t('crm.navigation.attendance')}`,
      action: this.onOpenAttendance,
    },
    {
      name: `${this.$t('crm.navigation.attachments')}`,
      action: this.onOpenAttachments,
    },
    {
      name: `${this.$t('crm.navigation.sendEmail')}`,
      action: this.onSendEmail,
    },
  ];

  dialogConfig: IKeyValue<IDialogConfig> = {
    confirmation: {
      message: '',
      color: '',
      show: false,
      onChoice: () => {},
    },
    sendEmail: {
      show: false,
    },
    advancedSearch: {
      show: false,
    },
    attachments: {
      show: false,
    },
    prospect: {
      show: false,
      hasExistingContacts: false,
    },
    attendance: {
      show: false,
    },
  };

  activeRoute: string | null | undefined = null;

  socketEvaluated = false;

  showCallCenter = false;

  createConversation: ICreateConversation | null = null;

  @Watch('$route')
  watchRoute(value: Route): void {
    if (value.name === 'CrmDashboard' && this.sessionService.activeClient) {
      this.activeRoute = value.name;
      if (this.sessionService.clientType !== this.internalClientType) {
        this.clientType = this.sessionService.clientType;
        this.internalClientType = this.clientType;
      }

      // eslint-disable-next-line operator-linebreak
      const client =
        this.clientType === ClientTypeEnum.Client
          ? this.sessionService.activeClient.cnpjCpf
          : this.sessionService.activeClient.codCliente;
      if (!client) {
        this.activeClient = null;
      } else {
        this.activeClient = this.sessionService.activeClient;
        this.onClientSelected(this.activeClient);
      }
    }
  }

  async mounted(): Promise<void> {
    const currentRoute = this.routerService.route();
    const routeParams = currentRoute && currentRoute.params;
    const routeQuery = currentRoute && currentRoute.query;

    if (routeParams.previousRoute === 'CrmAttendantPanel') {
      this.activeRoute = 'CrmAttendantPanel';
    } else {
      this.activeRoute = currentRoute.name;
    }

    const loader = this.$loading.show();

    await this.loadSettings();
    await this.loadUserContactInfo();

    const clientId = routeParams?.clientId;
    if (clientId && this.activeClient === null) {
      await this.loadClient(clientId);
    }

    try {
      const partnerCode = this.sessionService.partnerCodeCallCenter;
      // Configuracao call center;
      if (this.sessionService.websocketCallCenter && parseInt(partnerCode, 10) === CallCenterPartnerEnum.EOX) {
        this.showDrawerCallCenter = true;
        this.overlayCallCenter = true;
        this.drawerCallCenter = true;

        if (this.$callCenter.configure) {
          this.$callCenter.configure(
            this.sessionService.websocketCallCenter,
            this.sessionService.extensionCallCenter,
            this.sessionService.idCallCenter,
            this.sessionService.showNotificationCallCenter,
            this.sessionService.unitIdentifierCallCenter,
          );
        }

        this.$callCenter.subscribe.$on(CallCenterEventEnum.Error, (msg: string) => {
          if (msg) {
            this.$notify.error(msg);
          }
        });

        this.$callCenter.subscribe.$on(CallCenterEventEnum.UserLogged, () => {
          this.overlayCallCenter = false;
        });

        this.$callCenter.subscribe.$on(
          CallCenterEventEnum.CallInProgress,
          (callInProgress: CallCenterCallModel, identifying: boolean) => {
            this.drawerCallCenter = true;
            if (!identifying && callInProgress != null) {
              if (callInProgress.tipoLigacao !== CallCenterCallTypeEnum.Active) {
                let directAccessClientId = '';
                let directAccessClientType = ClientTypeEnum.Client;

                if (callInProgress.tipo === ConversationTypeEnum.Client) {
                  directAccessClientId = callInProgress.cnpj;
                  directAccessClientType = ClientTypeEnum.Client;
                } else if (callInProgress.tipo === ConversationTypeEnum.Prospect) {
                  if (callInProgress.prospect != null) {
                    directAccessClientId = callInProgress.prospect.codProspect;
                    directAccessClientType = ClientTypeEnum.Prospect;
                  }
                }

                if (callInProgress.contato != null) {
                  if (callInProgress.contato.idProspect) {
                    directAccessClientId = callInProgress.contato.idProspect.toString();
                    directAccessClientType = ClientTypeEnum.Prospect;
                  } else if (callInProgress.contato.cnpj) {
                    directAccessClientId = callInProgress.contato.cnpj;
                    directAccessClientType = ClientTypeEnum.Client;
                  }
                }

                if (directAccessClientId) {
                  this.clientType = directAccessClientType;
                  this.sessionService.activeClient = new ClientModel();
                  this.sessionService.clientType = this.clientType;

                  this.loadClient(directAccessClientId);

                  this.routerService.navigate({
                    name: 'CrmDashboard',
                    params: { clientId: directAccessClientId },
                  });
                }
              }
            }
          },
        );
      }

      this.conversationUserPermission = await this.conversationService.getUserPermissions();
      this.settings = await this.settingsService.getSettings();

      const onOpenCreateConversation: string = routeQuery?.onOpenCreateConversation as string;
      if (onOpenCreateConversation) {
        const splittedData = onOpenCreateConversation.split(',');
        const contact = await this.contactService.getContact(parseInt(splittedData[0], 10));

        this.createConversation = {
          waContact: {
            waId: splittedData[1],
            input: splittedData[2],
            status: ConversationContactStatusEnum.Valid,
          },
          contact,
        };
      }
    } catch (error) {
      this.$notify.error(error && (error as Error).message);
    }

    try {
      if (this.sessionService && this.sessionService.socketIOUrl) {
        const debug = this.sessionService.socketIOUrl.indexOf('localhost') > -1;

        const socketInstance = io(this.sessionService.socketIOUrl, {
          reconnection: true,
          reconnectionAttempts: 9999,
          reconnectionDelay: 2000,
          reconnectionDelayMax: 5000,
          timeout: 10000,
        });

        const socket = new VueSocketIO({
          debug,
          connection: socketInstance,
        });
        Vue.use(socket);

        this.socketEvaluated = true;
      }
    } catch (error) {
      this.$notify.error(error && (error as Error).message);
    }

    loader.hide();
  }

  onClientTypeChange(): void {
    if (this.activeClient && this.internalClientType !== this.clientType) {
      const isProspect = this.internalClientType === ClientTypeEnum.Prospect;
      const message = isProspect
        ? 'crm.view.index.wouldLikeToChangeToProspect'
        : 'crm.view.index.wouldLikeToChangeToClient';

      this.beforeChangeClientType(`${this.$t(message)}`, async (accept: boolean) => {
        if (accept) {
          this.activeClient = null;
          this.clientType = this.internalClientType;
          this.sessionService.activeClient = new ClientModel();
          this.sessionService.clientType = this.clientType;
          this.routerService.navigate({ name: 'CrmHome' });
        } else {
          this.sessionService.activeClient = new ClientModel();
          this.internalClientType = this.clientType;
        }
      });

      return;
    }

    this.clientType = this.internalClientType;
    this.sessionService.clientType = this.clientType;
    this.routerService.navigate({ name: 'CrmHome' });
  }

  beforeChangeClientType(message: string, onChoice: CallableFunction): void {
    this.dialogConfig.confirmation.message = message;
    this.dialogConfig.confirmation.color = 'red';
    this.dialogConfig.confirmation.onChoice = onChoice;
    this.dialogConfig.confirmation.show = true;
  }

  onClientSelected(client: ClientModel): void {
    if (!client) {
      this.activeClient = null;
      this.routerService.navigate({ name: 'CrmHome', params: { clientId: '' } });
      return;
    }

    this.activeClient = client;
    const clientId = client.type === ClientTypeEnum.Client ? client.cnpjCpf : client.codCliente;

    this.loadClient(clientId);

    this.routerService.navigate({ name: 'CrmDashboard', params: { clientId } });
  }

  onSendEmail(): void {
    this.dialogConfig.sendEmail.show = true;
  }

  onOpenAttachments(): void {
    this.dialogConfig.attachments.show = true;
  }

  onOpenAttendance(): void {
    this.dialogConfig.attendance.show = true;
  }

  onAdvancedSearch(): void {
    this.dialogConfig.advancedSearch.show = true;
  }

  onClickPrevious(): void {
    this.$router.back();
  }

  onAddProspect(): void {
    this.dialogConfig.prospect.show = true;
  }

  onImportProspects(): void {
    // Ajuste paleativo para não remover o botão 'Importar Prospects' do painel de relacionamento
    const url = this.prospectService.getNewTabUrl();
    window.open(url, '_blank');
    // this.routerService.navigate({ name: 'CrmImportProspects' });
  }

  onAfterSaveProspect(model: ProspectModel): void {
    this.dialogConfig.prospect.hasExistingContacts = false;
    this.dialogConfig.prospect.show = false;

    this.loadClient(model.codProspect);

    this.routerService.navigate({ name: 'CrmDashboard', params: { clientId: model.codProspect } });
  }

  get secondaryMenuData(): INavData[] {
    if (this.settings?.flagHabilitaMenuVisitas === false) {
      this.fixedSecondaryMenuData = this.fixedSecondaryMenuData.filter((item) => item.route !== 'CrmVisitsMade');
    }
    if (this.settings?.flagHabilitaMenuClientesCidadeProspect) {
      if (!this.fixedSecondaryMenuData.includes(this.prospectCityClientsMenu)) {
        this.fixedSecondaryMenuData = [this.prospectCityClientsMenu, ...this.fixedSecondaryMenuData];
      }
    } else {
      this.fixedSecondaryMenuData = this.fixedSecondaryMenuData.filter(
        (item) => item.route !== 'CrmProspectCityClients',
      );
    }

    // incluir menu Ligações
    const menuCallCenter: any = [];
    if (this.sessionService.websocketCallCenter || this.sessionService.partnerCodeCallCenter === '20') {
      // Incluir o menu Ligações se ativo
      menuCallCenter.push({
        name: `${this.$t('crm.navigation.calls')}`,
        icon: 'mdi-phone',
        route: 'CrmCalls',
      });
    }

    if (this.settings) {
      if (this.settings.flagMenuVendasColecaoErp) {
        this.fixedSecondaryMenuData = this.fixedSecondaryMenuData.filter((item) => item.route !== 'CrmSales');
      } else {
        this.fixedSecondaryMenuData = this.fixedSecondaryMenuData.filter((item) => item.route !== 'CrmSalesErp');
      }
    }

    return [
      {
        children: [
          {
            name: `${this.$t('crm.navigation.dashboard', { clientType: this.$t(`crm.${this.clientType}`) })}`,
            icon: 'mdi-card-account-details-outline',
            route: 'CrmDashboard',
          },
          ...this.fixedSecondaryMenuData,
          ...menuCallCenter,
        ],
      },
    ];
  }

  onActiveCallCenterDrawer() {
    this.drawerCallCenter = true;
  }

  onCloseCallCenterDrawer() {
    this.drawerCallCenter = false;
  }

  get isProspectType(): boolean {
    return this.clientType === ClientTypeEnum.Prospect;
  }

  get showAddProspectBtn(): boolean {
    return !!(!this.isBuiltInMode && this.isProspectType);
  }

  get secondaryMenu(): INavData[] {
    if (this.isProspectType) {
      const prospectMenu = [
        'CrmDashboard',
        'CrmProspectCityClients',
        'CrmCalendar',
        'CrmAttendance',
        'CrmProcesses',
        'CrmEmails',
        'CrmChats',
        'CrmCalls',
      ];

      return [
        {
          children: (this.secondaryMenuData[0]?.children || []).filter(
            (item) => item.route && prospectMenu.includes(item.route),
          ),
        },
      ];
    }

    return this.secondaryMenuData;
  }

  get prospectCityClientsMenu(): INavData {
    return {
      name: `${this.$t('crm.navigation.prospectCityClients')}`,
      icon: 'mdi-account-group',
      route: 'CrmProspectCityClients',
    };
  }

  get prospectFormDialogTitle(): string {
    let titleKey!: string;
    if (this.dialogConfig.prospect.hasExistingContacts) {
      titleKey = 'crm.view.dashboard.dialog.contactForm.existingContactsTitle';
    } else titleKey = 'crm.view.index.dialog.prospect.title';
    return `${this.$t(titleKey)}`;
  }

  get attendanceDialogTitle(): string {
    return this.$t('crm.view.index.dialog.attendance.title', {
      clientType: this.$t(`crm.${this.clientType}`)
        .toString()
        .toLowerCase(),
    }).toString();
  }

  get mainTitle(): string {
    return (this.activeClient && (this.activeClient.nomeFantasia || this.activeClient.nome)) || '';
  }

  get disableMenu(): boolean {
    return !this.activeClient || !this.activeClient.codCliente || !this.routerService.route().params.clientId;
  }

  get isBuiltInMode(): boolean {
    return this.sessionService && this.sessionService.builtInMode;
  }

  get hideAppBar(): boolean {
    return this.sessionService && this.sessionService.hideAppBar;
  }

  get chatHeight(): string | number {
    let diff = this.showAppBarExtension ? 124 : 64;
    diff = this.isBuiltInMode ? 64 : diff;
    if (this.hideAppBar) {
      diff = 0;
    }
    return `calc(100vh - ${diff}px)`;
  }

  get showAppBarExtension(): boolean {
    return this.activeRoute === 'CrmHome' || this.activeRoute === 'CrmDashboard';
  }

  get isOpenByKanban(): boolean {
    return this.sessionService.builtInMode;
  }

  get showChat(): boolean {
    const permissions = this.conversationUserPermission;
    const hasPermission = permissions?.viewWhatsApp && permissions?.manageWhatsApp;
    const isReady = this.userContactInfo && this.conversationUserPermission && this.socketEvaluated;

    return !!(isReady && hasPermission);
  }

  get showSecondaryMenu(): boolean {
    return this.activeRoute !== 'CrmChatOverview';
  }

  get showPreviousBtn(): boolean {
    return !this.showSecondaryMenu || this.activeRoute === 'CrmAttendantPanel';
  }

  private async loadSettings(): Promise<void> {
    this.settings = await this.settingsService.getSettings();
  }

  private async loadClient(clientId: string): Promise<void> {
    try {
      this.activeClient = await this.clientService.getSummary(clientId, this.clientType);

      // eslint-disable-next-line operator-linebreak
      this.activeClient.nomeFantasia =
        (this.activeClient.nomeFantasia || '').trim() || (this.activeClient.nome || '').trim();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  private async loadUserContactInfo(): Promise<void> {
    try {
      this.userContactInfo = await this.contactService.getLoggedUserContactInfo();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  created() {
    eventBus.$on('edit-prospect-complete', this.handleSaveComplete);
  }

  handleSaveComplete(model: ProspectModel) {
    this.onAfterSaveProspect(model);
  }
}
